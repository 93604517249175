<template>
<div class="home-footer-nav-box">
<div style="display:none;">
<div class="home-footer-nav-titel">
<span style="color:#3b3b3b;">روابط هامة</span>
</div>
<a href="https://onekod.com/home">الصفحة الرئيسية</a>
<a href="https://onekod.com/how_are_we">من نحن</a>
<a href="https://onekod.com/package">الباقات</a>
<a href="https://onekod.com/support">الدعم الفني</a>
<a href="https://onekod.com/terms">الشروط والاحكام</a>
</div>
<div class="home-footer-nav-titel">
<span style="color:#3b3b3b;font-size:22px;padding-bottom:24px;">روابط هامة</span>
</div>
<div class="home-footer-nav-item">
<a href="#how_are_we" class="footer-nav-link"
v-on:click="page_scroll_to('pg-header')">من نحن</a>
</div>
<div class="home-footer-nav-item">
<a href="#packege" class="footer-nav-link"
 v-on:click="page_scroll_to('pg-packege')">الباقات</a>
</div>
<div class="home-footer-nav-item">
<a href="https://api.whatsapp.com/send/?phone=966581015799&text&type=phone_number&app_absent=0"
target="_blank" class="footer-nav-link">الدعم الفني</a>
</div>
<div class="home-footer-nav-item">
<a href="#" class="footer-nav-link">الشروط والاحكام</a>
</div>
</div>

</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'FooterTime',
components: {},
//props: {msg: String},
data(){
return {
     
}
},
methods: {
page_scroll_to:function(id){
//var page_scroll_Height = document.getElementById("j7app-containr").scrollHeight;
var scr_id=document.getElementById(id).offsetTop;
var scr_top=parseInt(scr_id.toString().replace("-",""));
$('.j7app-containr').animate({scrollTop: scr_top}, 400, function(){});
}
//
},
watch:{

},
computed: {
...mapState({
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-footer-nav-box{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;height:300px;
align-content:center;justify-content:center;
}
.home-footer-nav-titel{
position:relative;display:flex;flex-wrap:wrap;
width:100%;height:auto;align-content:center;justify-content:center;
margin-bottom:7px;
}
.home-footer-nav-titel h4{
position:relative;display:flex;flex-wrap:wrap;padding:0;
width:100%;height:36px;align-content:center;justify-content:center;
}
.home-footer-nav-titel span{
position:relative;display:flex;flex-wrap:wrap;padding:0;
width:100%;height:32px;align-content:center;justify-content:center;
}
.home-footer-nav-item{
position:relative;display:flex;flex-wrap:wrap;
width:100%;align-content:center;justify-content:center;
padding-top:7px;padding-bottom:7px;height:auto;

}
.home-footer-nav-item:last-child{border-bottom:none;}
.home-footer-nav-item a{
position:relative;display:flex;flex-wrap:wrap;
width:94%;align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;color:#3b3b3b;
}


@media (min-width: 800px){
.home-footer-nav-box{
padding-top:7px;
}
}

</style>