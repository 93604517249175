<template>
<div class="j7app-main-com" dir="rtl" style="position:relative;
background:#fff url(/img/photo-15494.jpg);
background-repeat: no-repeat;
background-position:center;
background-size: cover;">
<!--:style="view_Nav=='menu'&&header_type=='fixed'?'padding-top:152px;':''"-->
<div class="main-home-page j7app-main-home" style="
background: rgba(255,255,255,.96);
backdrop-filter: blur(5px);-webkit-backdrop-filter: blur(5px);max-width:100%;">
<Header id="pg-header"></Header>
<Package id="pg-packege"></Package>
<Contact id="pg-contact"></Contact>
<Footer id="pg-footer"></Footer>
</div>

<div class="LoadBar" style="background:#fff;position:fixed;width:100%;
height: 100vh; height: -webkit-fill-available;
touch-action: manipulation;
z-index:10;top:0;left:0;display:flex;flex-wrap:wrap;">
<LoadBar @load_state="emit_load_state($event)" :plus="5" :time="10"></LoadBar>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import LoadBar from '../components/LoadBar.vue';
import Header from '../components/Header.vue';
import Package from '../components/Package.vue';
import Contact from '../components/Contact.vue';
import Footer from '../components/Footer.vue';
export default {
name: 'Homepage',
components: {
LoadBar,Header,Package,Contact,Footer
},
data() {
return {
code_view:'',
header_type:'',
prog:0
}
},
methods: {
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
emit_load_state:function(event){ 
this.prog = event;
}
},
destroyed(){
window.removeEventListener('resize',  () => {});
},
mounted(){
this.$store.commit("set_page_title",'كود واحد | One Kod');
this.header_type=$('.j7app-header-home').css("position");
window.addEventListener('resize',  () => {
this.header_type=$('.j7app-header-home').css("position");
});
},
updated() {
//alert('updated');
},
watch:{
prog(){
if(this.prog==100) {
$('.LoadBar').hide();
}
}
},
computed: {
...mapState({
pageview : state => state.pageview,
view_Nav : state => state.view_Nav,
pop_like : state => state.pop_like,
pop_story : state => state.pop_story,
cust_name : state => state.cust_name
})
}
}
</script>
<style>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;height: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-header-home{
z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
background:#f7f6f6;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
background:#fff;
}
</style>
<style scoped>
.pop-up-story{z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);touch-action: pan-x pan-y!important;
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
display:flex;flex-wrap:wrap;width:100%;min-height: 100vh;max-height:100vh;overflow:hidden;
min-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-items: flex-start;justify-content:center;
cursor:pointer;
}
.pop-up-like{
z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);height:100%;
display:flex;flex-wrap:wrap;width:100%;max-height:100vh;overflow:hidden;
max-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-content:center;justify-content:center;
cursor:pointer;
}
@media (min-width: 200px) and (max-width: 992px){
}
@media (min-width: 992px){
}
</style>