<template>
<div class="home-footer-info-box">
<h2 style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:24px;color:#3b3b3b;">
مؤسسة واحد كود </h2>
<p style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:18px;color:#3b3b3b;padding:14px;">
نقدم لك أفضل الحلول الرقمية  التي تساعدك في عرض جميع منتجاتك وخدماتك لعملائك وجذب اهتمامهم بعلامتك التجارية .
</p>
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
name: 'FooterInfo',
components: {},
//props: {msg: String},
data(){
return {
     
}
},
methods: {

},
watch:{

},
computed: {
...mapState({
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-footer-info-box{
position:relative;display:flex;
padding:14px;padding-top:14px;
flex-wrap:wrap;
width:100%;height:300px;
align-content:flex-start;justify-content:center;
}
.home-footer-info-box p{
position:relative;display:flex;
flex-wrap:wrap;
width:100%;height:auto;font-size:13px;line-height:24px;
align-content:center;justify-content:center;text-align:center;
}
.home-footer-info-box img{

}
@media (min-width: 200px){
.home-footer-info-box{
height:auto;
}
.home-footer-info-box p{
width:94%;padding:0px;text-align:center;
margin-right:auto;margin-left:auto;
}
}
@media (min-width: 450px){
.home-footer-info-box{
height:auto;
}
.home-footer-info-box p{
width:100%;padding-left:50px;padding-right:50px;
margin-right:auto;margin-left:auto;
}
}
@media (min-width: 600px) and (max-width: 800px){
.home-footer-info-box{
height:300px;
}
.home-footer-info-box p{
width:100%;padding-left:0px;padding-right:0px;
margin-right:auto;margin-left:auto;text-align:center;
overflow-x:hidden;overflow-y:auto;max-height:168px;
scrollbar-width:none;
}
}

@media (min-width: 992px) and (max-width: 1400px){
.home-footer-info-box{
height:300px;
}
.home-footer-info-box p{
width:100%;padding-left:0px;padding-right:0px;
margin-right:auto;margin-left:auto;padding-top:10px;
overflow-x:hidden;overflow-y:auto;max-height:168px;
scrollbar-width:none;
}

}

@media (min-width: 1400px){
.home-footer-info-box p{
width:100%;padding-left:24px;padding-right:24px;
margin-right:auto;margin-left:auto;
}
}
</style>