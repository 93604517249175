<template>
<div id="pg-pkg" class="ok-pkg-warp" style="height:auto;max-width:1024px;
align-content:flex-start;background:none;">
<div class="ok-pkg-top-title" style="align-items:center;">
<span class="ok-pkg-top-title-icon" :style="'background:'+color+';'"></span>
<span class="ok-pkg-top-title-txt" style="
font-size:21px;color:#4e4e4e;">مميزات خدمتنا..</span>
</div>
<div class="ok-pkg-fbox" style="max-width:auto;max-height:730px;overflow:hidden;">
<vue-horizontal class="horizontal" ref="horizontal" :button="false"
style="display:flex;flex-wrap:wrap;width:100%;">
<div class="ok-pkg-item" v-for="(pkg, index_pkg) in package" :style="'background:#fff;border:3px solid '+pkg.color+';'">
<div class="ok-pkg-item-fix-title" style="top:-20px;" :style="'background:'+pkg.color+';'">
<span style="font-size:18px;"> {{pkg.name}}</span>
</div>
<div class="ok-pkg-item-footer" style="font-size:18px;" v-if="pkg.price!=''">
<div class="ok-pkg-item-footer-title" style="height:45px;">
<span style="padding-right:28px;font-size:18px;" v-if="pkg.price!=''">
سعر الباقة / سنويًا
</span>
</div>
<div class="ok-pkg-item-footer-box" style="height:100px;" v-if="pkg.price!=''">
<div class="ok-pkg-item-footer-price" style="height:auto;">
<div class="ok-pkg-item-footer-price-item" >
<span style="padding-right:18px;font-size:32px;color:#4e4e4e;">{{pkg.price}}</span>
<span style="font-size:18px;padding-right:5px;color:#4e4e4e;">ريال</span>
</div>
</div>
<div v-if="pkg.price==''" class="ok-pkg-item-footer-btn"
 v-on:click="page_scroll_to('pg-contact')"
 style="height:auto;padding-right:5px; width:94%; " v-opacity>
<span style="background:#4e4e4e;font-size:18px;"
:style="'color:'+pkg.color+';'">تواصل معنا الان</span>
</div>
<div v-if="pkg.price!=''" class="ok-pkg-item-footer-btn" v-on:click="page_scroll_to('pg-contact')"
 style="height:auto;padding-right:5px;" v-opacity>
<span style="background:#4e4e4e;font-size:18px;"
:style="'color:'+pkg.color+';'">ابدا الان</span>
</div>
</div>
</div>
<div v-opacity class="ok-pkg-item-head" style="height:60px;font-size:18px;align-items:center;cursor:pointer;"
v-on:click="more_view=!more_view">
<span style="padding-left:6px;padding-right:18px;font-size:18px;">مميزات الباقة</span>
<svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
<path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
</svg>
</div>
<div 
v-show="more_view==true" 
class="ok-pkg-item-list" style="font-size:18px;align-content:flex-start;">
<span v-for="(info, index_pkg) in pkg.info" style="font-size:15px;">
{{info}}.</span>
</div>
</div>
</vue-horizontal>
</div>
<div class="ok-pkg-nav-count" style="margin-top:14px;">
<span class="ok-pkg-nav-count-link active" v-for="(link, index_link) in package" 
:style="select_index==index_link?'width:36px;background:'+color+';'
:'width:24px;background:#a1a1a1;'"
 v-on:click="select_index=index_link,color=link.color,pkg_swipe(index_link)">
<span style="display:flex;flex-wrap:wrap;width:24px;height:35px;position:absolute;top:-15px;"></span>
</span>
</div>
<div class="ok-pkg-nav">
<div class="ok-pkg-nav-item" v-for="(nav, nav_index) in package"
 v-on:click="select_index=nav_index,color=nav.color,pkg_swipe(nav_index)">
<div class="ok-rap-span" style="height:92px;width:128px;border-radius:8px;margin-top:14px;"
:style="select_index==nav_index?'border:1px solid '+'#a1a1a1'+';':'border:1px solid #ddd;'">
<span style="width:100%;height:24px;background:none;">⚡</span>
<div class="ok-rap-span" style="width:94%;font-size:14px;text-align:center;">{{nav.name}}</div>
</div>
</div>
</div>
</div>
</template>
<script>
import VueHorizontal from 'vue-horizontal';
export default {
name: 'Package',
components: {VueHorizontal},
//props: ['plus','time'],
//emits:['load_state'],
data(){
return {
more_view:false,
select_index:0,
width:window.innerWidth,
color:'#fdecaf',
package:[
{
color:'#fdecaf',name:'المنيو الذكي',price:'800',
info:[
//'QRcode وصفحة رئيسية بتصميم تفاعلي لعرض جميع منتجاتك وخدمتك ',
'عرض جميع الاصناف والتنصيفات',
'دعم الاستلام من الفرع وطلبات السيارة والتوصيل',
'استقبال والغاء الطلبات بذكاء الصناعي',
'صفحة رئيسية و كيوار كود احترافي بمنطقة البيع',
//'مفضلة - بحث ذكي - تصنيفات - كلمات مفتاحيه - خاصية المزيد من العروض',
//'عرض ارقام التوصل واتساب الدعم',
//'عرض حسابات التواصل الاجتماعي',
'احصائيات متقدمة بعدد الزيارات والاصناف الاكثر مشاهدة',
'المساعدة في اضافة المحتوى ودعم فني على مدار الساعة',
]
},
{
color:'#fdd2af',name:'المنصة الأساسية',price:'1800',
info:[
//'QRcode وصفحة رئيسية بتصميم تفاعلي لعرض جميع منتجاتك وخدمتك ',
'عرض جميع منتجاتك وخدماتك',
'اضافة الصور والفيدوهات',
'كيو ار كود تفاعلي بمنطقة البيع',
//'مفضلة - بحث ذكي - تصنيفات - كلمات مفتاحيه - خاصية المزيد من العروض',
'عرض اوقات العمل والموقع الخريطة',
//'عرض ارقام التوصل واتساب الدعم',
//'عرض حسابات التواصل الاجتماعي',
'لوحة تحكم خاصة بادارة المحتوى',
'تصميم متوافق مع جميع الشاشات',
'دومين خاص (com.) لمدة عام',
'المساعدة في اضافة المحتوى',
'دعم فني على مدار الساعة',
]
},
{
color:'#fdb8af',name:'المنصة المميزة',price:'2400',
info:[
'كل مميزات الباقة الأساسية',
'السوم بذكاء الصناعي مع الإحصائيات',
'إحصائيات متقدمة لمنتجاتك وخدماتك',
'أرشفة متقدمة بمحرك بحث Google',
'إضافة خدمات تحليلات Google',
'إنشاء الحملات الإعلانية الممولة',
'تصميم إعلان تعريفي بالمنصة لمواقع التواصل الاجتماعي',
'تصميم فيديو ترويجي خاص بلمنصة ',
]
},
{
color:'#fdecaf',name:'المنصة الخاصة',price:'3600',
info:[
'فريق كامل من المبرمجين',
'تصميم مميز يناسب اعمالك',
'تواصل معنا وميز منصتك',
]
}
]
}
},
methods: {
pkg_swipe: function (index){ 
this.$refs.horizontal.scrollToIndex(index);
},
page_scroll_to:function(id) {
//var page_scroll_Height = document.getElementById("j7app-containr").scrollHeight;
var scr_id=document.getElementById(id).offsetTop;
var scr_top=parseInt(scr_id.toString().replace("-",""));
$('.j7app-containr').animate({scrollTop: scr_top}, 400, function(){});
}
},
mounted(){

},
watch:{

}
}
</script>
<style scoped>
.ok-rap-span{
display:flex;flex-wrap:wrap;
align-content:center;justify-content:center;
}
.ok-pkg-warp{
position:relative;z-index:0;
width: 100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
font-family: 'Noto Kufi Arabic', sans-serif;
}
.ok-pkg-top-title{
position:relative;display: flex;flex-wrap: wrap;
justify-content:center;align-content:center;
height:72px;width:100%;
margin-top:18px;margin-bottom:18px;
}
.ok-pkg-top-title-icon{
display: flex;flex-wrap: wrap;align-content:center;
background:#fc9975;height:32px;width:10px;
}
.ok-pkg-top-title-txt{
display: flex;flex-wrap: wrap;justify-content:center;
align-content:center;width:auto;font-weight:bold;
padding-right:18px;padding-left:18px;
}
.ok-pkg-fbox{
position:relative;z-index:0;
display: flex;flex-wrap: wrap;
height:auto;width:100%;
align-content:flex-start;justify-content:space-between;
margin-right:auto;margin-left:auto;
}
.ok-pkg-div{
position:relative;display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
}
.ok-pkg-item{
position:relative;display: flex;flex-wrap: wrap;
margin-top:18px;margin-bottom:18px;padding-top:48px;
align-content:flex-start;padding-right: 18px;padding-left: 18px;
height:auto;width:100%;border-radius:8px;
}
.ok-pkg-item-fix-title{
position:absolute;display: flex;flex-wrap: wrap;
height:38px;width:50%;max-width:152px;
align-content:center;justify-content:center;
border-radius:4px;
}
.ok-pkg-item-head{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:right;margin-bottom:18px;
width:100%;position:relative;
border-radius:4px;
}
.ok-pkg-item-list{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:right;
width:100%;position:relative;
border-radius:4px;height:auto;
}
.ok-pkg-item-list span{
display: flex;align-content:center;line-height:30px;
justify-content:right;align-items:flex-start;margin-bottom:14px;
width:100%;position:relative;white-space:normal;
}
.ok-pkg-item-list span::before{
content: "-";
display: flex;padding-left:14px;padding-right:14px;
height:auto;width:5px;
}
.ok-pkg-item-footer{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:right;
height:auto;width:100%;position:relative;
}
.ok-pkg-item-footer-title{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:right;
width:100%;position:relative;
}
.ok-pkg-item-footer-box{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:right;
width:100%;position:relative;
}
.ok-pkg-item-footer-price{
display: flex;flex-wrap: wrap;
align-content:center;justify-content:right;
position:relative;border-radius:4px;width:50%;
}
.ok-pkg-item-footer-price-item{
display: flex;flex-wrap: wrap;width:auto;
height:100%;align-content:center;justify-content:center;
}
.ok-pkg-item-footer-price-item span{
display: flex;flex-wrap: wrap;width:auto;align-content:center;
height:100%;align-content:center;justify-content:center;
}
.ok-pkg-item-footer-btn{
position:relative;display: flex;flex-wrap: wrap;
width:50%;align-content:center;justify-content:center;
border-radius:4px;cursor:pointer;opacity:1;
}
.ok-pkg-item-footer-btn:hover{opacity:0.9;}
.ok-pkg-item-footer-btn span{
display: flex;flex-wrap: wrap;width:90%;height:60px;
align-content:center;justify-content:center;
font-size:18px;max-width:152px;border-radius:8px;
}
.ok-pkg-nav-count{
display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
width:94%;height:auto;margin-right:auto;margin-left:auto;
}
.ok-pkg-nav-count-link{
display:flex;flex-wrap:wrap;position:relative;
justify-content:center;align-content:center;
margin-left:5px;height:5px;cursor:pointer;
opacity:0.8;border-radius:6px;
}
.ok-pkg-nav{
display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
width:100%;height:auto;margin-right:auto;margin-left:auto;
padding-right:7px;padding-left:7px;
margin-top:18px;margin-bottom:18px;
}
.ok-pkg-nav-item{
display:flex;flex-wrap:wrap;cursor:pointer;
justify-content:center;align-content:center;
margin-left:14px;
}
.ok-pkg-nav-item span{
display:flex;flex-wrap:wrap;
width:100%;height:100%;background:#fff;color:#3b3b3b;
justify-content:center;align-content:center;
font-size:13px;border-radius:8px;white-space:nowrap;
}
</style>
<style scoped>
@media (min-width: 200px) and (max-width: 575px){
.ok-pkg-item{
width:100%;
}
.ok-pkg-fbox{width:90%;justify-content:flex-start;}
}
@media (min-width: 575px) and (max-width: 720px){
.ok-pkg-item{
width:100%;
}
.ok-pkg-fbox{width:90%;justify-content:flex-start;}
}
@media (min-width: 720px) and (max-width: 1024px){
.ok-pkg-item{
width:47%;margin-left:2%;
}
.ok-pkg-fbox{width:90%;justify-content:flex-start;}
}
@media (min-width: 1024px){
.ok-pkg-item{
width:31%;margin-left:3%;
}
.ok-pkg-item:last-child{
margin-right:0%;
}
.ok-pkg-fbox{justify-content:space-between;}
}
</style>