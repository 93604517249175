<template>
<div class="j7app" style="">
<!--background: linear-gradient(135deg,black, #343a40);-->
<div id="j7app-containr" class="j7app-containr" style="">
<div class="j7app-page">
<router-view></router-view>
<!--<ItemView v-show="preview==='ViewItem'"></ItemView>-->
<!--<Homepage></Homepage>-->
<!--<KeepAlive>
</KeepAlive>-->
<!--<component :is="preview"></component>-->
<!--<ItemView></ItemView>-->
<!--</KeepAlive>-->
</div>
</div>
</div>
</template>
<script>
import { mapState} from "vuex";
//import Homepage from "./views/Homepage.vue"
//import ItemView from "./views/ItemView.vue"
//import ShopCart from "@/components/ShopCart.vue"
//import CartConfirm from "@/components/CartConfirm.vue"
//import OrderList from "@/components/OrderList.vue"
//import OrderView from "@/components/OrderView.vue"
//import CartList from "@/components/CartList.vue"
//import OptionList from "@/components/OptionList.vue"
export default {
name: 'App',
components: {
      
},

data: () => ({
//likes:null
}),
methods: {
itemswiap: function (e, val) {
//e.deltaX // e.target
var deltaNowX = 0;
var el = val;
deltaNowX = e.deltaX;
//$(el).hide();
//alert(deltaNowX);
if (deltaNowX > 200) {
			
}
else if (deltaNowX < -200) {
				
}
else {
			
}
},
dis_App_Json:function (){
//GetAppJson --- GetApiJson
//this.$store.commit("GetApiJson","I5577488-IJAZ-4757-8D37-E64F1316DBED");
//if(!this.$cookies.isKey("uid")){
//this.$cookies.set('uid',this.$uuid.v4());
//this.$cookies.set('likes',this.likes);
//}
//var like=this.$cookies.get("likes");
//this.$store.commit("set_likes",like);
//this.likes=like;
},
test:function (){
alert('test');
},
scrollFunction: function (){

}
},
created() {
this.dis_App_Json();
},
destroyed(){
},
mounted() {
this.dis_App_Json();
//this.$vuetify.rtl = true;set_page_title
//this.$vuetify.lang.current = 'ar';
this.$store.commit("set_page_title",'');
$('.app-main-page').css('display', 'block');
},
watch:{
page_title(){
document.title=this.page_title;
}
},
computed: {
...mapState({
page_title : state => state.page_title,
likes : state => state.likes,
cust_name : state => state.cust_name
})
}
};
</script>
<style scoped>
.j7app{
position: relative;display: block;height: 100%;min-height: 100vh;max-height:100%;
width: 100%;touch-action: none;overflow-x:hidden;scrollbar-width:thin;
min-height: -webkit-fill-available; user-select: none;direction:rtl;
margin-right:auto;margin-left:auto;
}
.j7app-containr{
display: flex;flex-wrap: wrap;user-select: none;
height: 100vh;overflow-y:auto;scroll-behavior:smooth;
height: -webkit-fill-available;
max-height: 100vh;
max-height: -webkit-fill-available;
width: 100%;margin-right:auto;margin-left:auto;
direction:ltr;max-width:100%;overflow-x:hidden;overflow-y:scroll;
}
.j7app-page{
display: flex;flex-wrap: wrap;width: 100%;margin-right:auto;margin-left:auto;
max-width:100%;direction:rtl;
}
@media (min-width: 800px){
.j7app-page{
/*
max-width:768px;
*/
position:relative;
}
}
</style>