<template>
<div class="onekod-header-warp"
 style="
height:auto;max-width:1024px;opacity:1;
align-content:flex-start;">
<div class="onekod-fbox" style="position:relative;height:auto;
align-content:flex-start;
width:100%;">
<div class="onekod-tophome-right"></div>
<div class="onekod-header-top" style="height:100px;align-content:center;
padding:18px;">
<div class="onekod-logo-box" style="width:auto;height:72px;">
<img alt="onekod" src="/img/logo.png" style="max-height:200px;
max-width:200px;position:relative;opacity:0.9;" />
</div>
</div>
<div class="onekod-fbox" style="width:100%;height:auto;margin-top:18px;">
<div class="bg-header-z1" style="position:relative;
width:94%;height:100%;opacity:0.9;">
<div style="width:100%;height:300px;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
position:relative;">
<div style="display: flex;flex-wrap: wrap;justify-content:center;align-content:center;
border:1px solid #ddd;position:relative;width:90%;max-width:320px;
height:100%;border-radius:50%;
background:none">
<div style="background:#fbf5e1;border-radius:50%;display: flex;flex-wrap: wrap;position:relative;height:80%;width: 80%;justify-content:center;align-content:center;">
<!--<img class="onekod-fbox" src="/img/ya-500.png" style="
 height:100%;max-width:100%;object-fit: cover;" />-->
<img class="onekod-fbox" alt="onekod" src="/img/photo-16066-old.png" style="
opacity:0.9;position:absolute;bottom:-8px;
border-radius:0px 0px 0px 0px;
 max-height:100%;max-width:100%;object-fit: cover;" />
</div>
<div style="background:#575758;
border-radius:10px;text-align:center;
display: flex;flex-wrap: wrap;height:45px;width:45px;justify-content:center;
transform: rotate(20deg);opacity:0.9;
align-content:center;position:absolute;right:-21px;top:33.33%;">
<span style="display: flex;flex-wrap: wrap;align-content:center;justify-content:center;height:24px;width:24px;line-height:24px;">
<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" style="fill:rgba(255,255,255,.9);">
<path fill="#fff" d="M6.5 20Q4.22 20 2.61 18.43 1 16.85 1 14.58 1 12.63 2.17 11.1 3.35 9.57 5.25 9.15 5.88 6.85 7.75 5.43 9.63 4 12 4 14.93 4 16.96 6.04 19 8.07 19 11 20.73 11.2 21.86 12.5 23 13.78 23 15.5 23 17.38 21.69 18.69 20.38 20 18.5 20M6.5 18H18.5Q19.55 18 20.27 17.27 21 16.55 21 15.5 21 14.45 20.27 13.73 19.55 13 18.5 13H17V11Q17 8.93 15.54 7.46 14.08 6 12 6 9.93 6 8.46 7.46 7 8.93 7 11H6.5Q5.05 11 4.03 12.03 3 13.05 3 14.5 3 15.95 4.03 17 5.05 18 6.5 18M12 12Z" />
</svg>
</span>
<!--<span class="mdi mdi-18px mdi-cloud-outline"
 style="color:rgba(255,255,255,.9);height:24px;width:24px;line-height:24px;"></span>-->
</div>
<div style="background: rgba(255,255,255,.8);
box-shadow: 0 15px 25px rgba(0,0,0,.06);
transform: rotate(-20deg);opacity:0.8;
border-radius:10px;text-align:center;border:1px solid #f4f4f4;
display: flex;flex-wrap: wrap;height:45px;width:45px;justify-content:center;
align-content:center;position:absolute;left:-21px;top:33.33%;">
<span style="display: flex;flex-wrap: wrap;align-content:center;justify-content:center;line-height:24px;">
<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" style="fill:rgba(122, 116, 98,.9);">
<path d="M4,4H10V10H4V4M20,4V10H14V4H20M14,15H16V13H14V11H16V13H18V11H20V13H18V15H20V18H18V20H16V18H13V20H11V16H14V15M16,15V18H18V15H16M4,20V14H10V20H4M6,6V8H8V6H6M16,6V8H18V6H16M6,16V18H8V16H6M4,11H6V13H4V11M9,11H13V15H11V13H9V11M11,6H13V10H11V6M2,2V6H0V2A2,2 0 0,1 2,0H6V2H2M22,0A2,2 0 0,1 24,2V6H22V2H18V0H22M2,18V22H6V24H2A2,2 0 0,1 0,22V18H2M22,22V18H24V22A2,2 0 0,1 22,24H18V22H22Z" />
</svg>
</span>
<!--<span class="mdi mdi-18px mdi-qrcode-scan"
 style="color:rgba(122, 116, 98,.9);height:24px;width:24px;line-height:24px;"></span>-->
</div>
</div>
<!--<img class="onekod-fbox" src="/img/1bd.png" style="max-width:90%;
  height: auto;position:relative;object-fit: cover;" />-->

</div>
</div>
</div>
<div class="one-info" style="font-family: 'Noto Kufi Arabic', sans-serif;
display: flex;flex-wrap: wrap;position:relative;height:auto;width: 100%;
justify-content:center;align-content:center;margin-top:18px;padding-bottom:18px;">
<span style="display: flex;flex-wrap: wrap;justify-content:center;
align-content:center;width:100%;font-size:22px;color:#4e4e4e;font-weight:bold;
padding-right:18px;padding-left:18px;margin-top:18px;">
واحد كود | One Kod
</span>
<div style="display: flex;flex-wrap: wrap;width:100%;justify-content:center;align-content:center;">
<img class="onekod-fbox" src="/img/text-underline-vactor.svg" alt="onekod" style="
height:24px;max-width:80%;object-fit: cover;opacity:0.6;margin-top:18px;" />
</div>
<p style="display: none;flex-wrap: wrap;width:94%;max-width:480px;
justify-content:center;align-content:center;
padding-right:18px;padding-left:18px;margin-top:18px;background:none;
font-size:18px;color:#4e4e4e;text-align:center;">
نقدم لك أفضل الحلول الرقمية الى تساعدك في عرض جميع منتجاتك وخدمتك وزيادة اهتمام عملائك بعلامتك التجارية.
</p>
<p style="display: flex;flex-wrap: wrap;width:94%;max-width:480px;
justify-content:center;align-content:center;
padding-right:18px;padding-left:18px;margin-top:18px;background:none;
font-size:18px;color:#4e4e4e;text-align:center;">
نقدم لك أفضل الحلول الرقمية  التي تساعدك في عرض جميع منتجاتك وخدماتك لعملائك وجذب اهتمامهم بعلامتك التجارية .
</p>
<div class="one-info-btn"
 v-on:click="page_scroll_to('pg-packege')"
 style="background:none;display: flex;flex-wrap: wrap;width:100%;justify-content:center;
align-content:center;font-size:18px;padding-right:18px;padding-left:18px;margin-top:18px;">
<span v-opacity style="display: flex;flex-wrap: wrap;width:94%;justify-content:center;
background:#3b3b3b;width:94%;border-radius:48px;
padding: 18px 32px;box-shadow: 0px 4px 4px 0px #f5f5f5;
text-decoration: none;cursor:pointer;max-width:255px;
align-content:center;font-size:18px;color:#fef9e9;padding-right:18px;padding-left:18px;">
ابدا معنا الان
</span>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'Header',
components: {},
//props: ['plus','time'],
//emits:['load_state'],
data(){
return {
prog:0,interval:null
}
},
methods: {
emit_load_state: function (){ 
this.$emit('load_state', this.prog);
},
page_scroll_to:function(id) {
//var page_scroll_Height = document.getElementById("j7app-containr").scrollHeight;
var scr_id=document.getElementById(id).offsetTop;
var scr_top=parseInt(scr_id.toString().replace("-",""));
$('.j7app-containr').animate({scrollTop: scr_top}, 400, function(){});
}
},
mounted(){

},
watch:{

}
}
</script>
<style scoped>
.onekod-header-warp{
position:relative;z-index:0;
width: 100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
}
.onekod-tophome-left{
position:relative;z-index:0;
width:100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
}
.onekod-tophome-right{
position:relative;z-index:0;
width:100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
}
.onekod-header-top{
position:relative;z-index:0;
width:100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
}
.onekod-logo-box{
position:relative;display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
}
.onekod-logo-box img{

}
.onekod-fbox{
display:flex;flex-wrap:wrap;position:relative;
justify-content:center;align-content:center;
}
</style>
