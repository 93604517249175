import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import Cookies from 'js-cookie'
import loc_json from '../json/v1.json';
var lazyLoadInstance = new LazyLoad({});
Vue.use(Vuex)

export default new Vuex.Store({
state: {
view_order: [],
temp_addons: [],
temp_options: [],
db: [],
branch: [],
cats: [],
items: [],
likes: [],
posts_temp: [],
posts: [],
Order_Temp:[],
Order_List:[],
OrderItems:[],
height_navbar: 0,
hosturl:'https://cdn.shokapp.com',
api_url:'https://cdn.shokapp.com/api/',
dash_key:'',
log_state:'',
api_key:'',
network_connect: window.navigator.onLine,
loading: true,
update_post_val: '',
search_val: '',
hide_navbar: '',
order_count_time: '',
preview: '',
page_title: '',
pop_like:'',
pop_story:'',
story_guid:'',
preview_index: 0,
Item_qty: 1,
selctedCat: 'all',
viewCat: 'all',
currency: 'ر.س',
User_Lang: '',
User_Mobile: '',
User_Loc: 0,
Order_Mobile: '',
LastOrder: 0,
order_index: 1,
view_Nav: 'menu',//menu
cust_name: '',
pageview: 0,
},
getters: {
},
mutations: {
UpdatePostItems: function (state, {guid, item})
{
var index = state.posts.findIndex(x => x.guid == guid);
state.posts[index] = item;
},
save_post_state: function (state,msg){
var send_data = state.posts.sort((a, b) => a.id - b.id);
//alert(JSON.stringify(send_data));
//var jsonObj = JSON.stringify(val);
axios.post(state.api_url+'posts_edit.aspx?guid='+state.dash_key,send_data,{
headers: {
'Content-Type': 'application/json'
}
})
.then((response) => {
if(response.data.status=='ok'&&response.data.message=='done'){
if(msg!=''){
this.commit("Swal_Success", msg);
}
}
else{
this.commit("Swal_Error", response.data.status);
}
},(error) => {
this.commit("Swal_Error", error);
});
},
save_post_val: function (state,{val,msg}){
//var jsonObj = JSON.stringify(val);
axios.post(state.api_url+'posts_edit.aspx?guid='+state.dash_key,val,{
headers: {
'Content-Type': 'application/json'
}
})
.then((response) => {
if(response.data.status=='ok'&&response.data.message=='done'){
if(msg!=''){
this.commit("Swal_Success", msg);
}
}
else{
this.commit("Swal_Error", response.data.status);
}
},(error) => {
this.commit("Swal_Error", error);
});
},
Swal_Success: function (state,val) {
Swal.fire({
//warning
icon: 'success',
width: 320,
title: '<span style="font-size=15px!important;">'+val+'</span>',
showConfirmButton: false,
timer: 800
})
},
msgokay: function (state,val) {
Swal.fire({
//warning
width: "80%",
html: '<h5>'+val+'</h5>',
showConfirmButton: false,
timer: 300
})
},
msginfo: function (state,val) {
Swal.fire({
//warning
width: 325,
html: $(val).html(),
showCloseButton: true,
focusConfirm: false,
showConfirmButton: false,
timer: 5000
})
},
Swal_Error: function (state, val)
{
Swal.fire({
 //warning
 icon: 'error',
 width: 325,
 html: '<h5>' + val + '</h5>',
 showConfirmButton: false,
 timer: 60000
})
},
Swal_Del: function (state,val) {
Swal.fire({
//warning
icon: 'error',
width: 325,
title: '...' + val,
showConfirmButton: false,
timer: 300
})
},
Swal_CartOff: function (state,{txt, msg}) {
Swal.fire({
title: 'هل تريد الاستمرار؟',
text: txt,
width: 325,
showCancelButton: true,
confirmButtonColor: '#c23350',
cancelButtonColor: '#2f353a',
cancelButtonText: 'تراجع',
confirmButtonText: 'نعم!',
}).then((result) => {
if (result.isConfirmed) {
Swal.fire({
icon: 'success',
title: msg,
showConfirmButton: false,
timer: 800
})
state.OrderItems = []; this.commit('CountOrderTotal');
}
})
 },
lazy_update(){
lazyLoadInstance.update();
},
SetViewCat(state, val){
state.viewCat = val;
},
set_pop_like(state, val){
state.pop_like = val;
},
set_story_guid(state, val){
state.story_guid = val;
},
set_pop_story(state, val){
state.pop_story = val;
},
set_update_post_val(state, val){
state.update_post_val = val;
},
Set_Order_Branch_View(state, val){
state.Order_Branch_View = val;
},
Set_Order_Mobile(state, val){
state.Order_Mobile = val;
},
Set_Order_Branch(state, {Name,ID}){
state.Order_Branch = Name;
state.Order_ID = ID;
},
setpage(state, val){
state.pageview = val;
},
setorder_count_time(state, val) {
state.order_count_time = val;
},
SetviewNav(state, val){
state.view_Nav = val;
},
Setheight_navbar(state, val) {
state.height_navbar = val;
},
Set_search_val(state, val) {
state.search_val = val;
},
SetHideNav(state, val){
state.hide_navbar = val;
},
SetPreview(state, val){
state.preview = val;
},
setitem_index(state, val){
state.preview_index= val;
},
set_likes(state, val){
state.likes= val;
},
push_likes(state, guid){
state.likes.push({"like":guid});
},
reset_posts(state,val) {
state.posts = val;
},
set_page_title(state,val) {
state.page_title = val;
},
set_dash_key(state, val) {
state.dash_key = val;
},
GetAppJson(state){
state.cats=loc_json.cats;
state.items=loc_json.items;
state.Order_List=loc_json.Order_List;
state.OrderItems=loc_json.OrderItems;
state.branch = loc_json.branch;
state.posts = loc_json.posts;
},
GetApiJson(state,val){
////Cookies.remove('dash_');
////Cookies.set("dash_","avbgt",{ expires: 1, path: '/'});
//var dash_= Cookies.get("dash_");
axios.get(state.api_url+'app.aspx?guid='+val)
.then((response) => {
if(response.data.status=='ok'){
state.cust_name=response.data.name
state.cats=response.data.cats;
state.posts_temp = response.data.posts;
state.posts = response.data.posts;
}
else{

}
},(error) => {
swal.fire({
width: 375,
icon: 'error',
title:'<h3>لايوجد اتصال مع السيرفر..</h3>',
html:'<h4 style="padding-top:14px!important;text-align:center;">'+error+'</h4>',
showConfirmButton: true,
//confirmButtonColor: '#4e4e4e',
confirmButtonText: 'رجوع',
});
});
}
},
actions: {
   
},
modules: {
}
})
