<template>
<div class="onekod-header-warp"
 style="
height:auto;max-width:1024px;opacity:1;
align-content:flex-start;">
<div class="onekod-fbox" style="position:relative;height:auto;
align-content:flex-start;
width:100%;">
<div class="onekod-header-top" style="height:100px;align-content:center;
padding:18px;">
<div class="onekod-logo-box" style="width:auto;height:72px;">
<img src="/img/logo.png" style="max-height:200px;
max-width:200px;position:relative;opacity:0.9;" />
</div>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'TopHeader',
components: {},
//props: ['plus','time'],
//emits:['load_state'],
data(){
return {
prog:0,interval:null
}
},
methods: {
emit_load_state: function (){ 
this.$emit('load_state', this.prog);
}
},
mounted(){

},
watch:{

}
}
</script>
<style scoped>
.onekod-header-warp{
position:relative;z-index:0;
width: 100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
}
.onekod-header-top{
position:relative;z-index:0;
width:100%;display: flex;flex-wrap: wrap;
align-content:center;justify-content:center;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
}
.onekod-logo-box{
position:relative;display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
}
.onekod-logo-box img{

}
.onekod-fbox{
display:flex;flex-wrap:wrap;position:relative;
justify-content:center;align-content:center;
}
</style>
