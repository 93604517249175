import Vue from 'vue'
import VueRouter from 'vue-router'
//import Index from '../views/Index.vue'
import Homepage from '../views/Homepage.vue'
import HomePackage from '../views/HomePackage.vue'
//import Codepage from '../views/Codepage.vue'
//import HomeItemView from '../views/HomeItemView.vue'
//import TestMode from '../views/TestMode.vue'
//import TestModeCom from '../views/TestModeCom.vue'
//import Likes from '../views/Likes.vue'
//import ViewStory from '../views/ViewStory.vue'
//import ItemView from '../views/ItemView.vue'
//import ItemEdit from '../views/ItemEdit.vue'

const { push } = VueRouter.prototype;
const { isNavigationFailure, NavigationFailureType } = VueRouter;
VueRouter.prototype.push = function (location) {
return push.call(this, location).catch(error => {
if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
throw Error(error)
}
})
};

Vue.use(VueRouter);

const routes = [
{
path: '/', redirect: '',
name: 'home',
component: Homepage
},
{
path: '/package',
name: 'HomePkg',
component: HomePackage
},
{
path: '/home',
name: 'Home2',
component: Homepage
},
{
path: '/who_are_we',
name: 'WhoPage',
component: Homepage
},
//
//{
//path: '/qrcode',
//name: 'qrcode',
//component: Codepage
//},
//{
//path: '/code',
//name: 'code',
//component: Codepage
//},
//{
//path: '/likes',
//name: 'Likes',
//component: Likes
//},
//{
//path: '/story',
//name: 'story',
//component: ViewStory
//},
//{
//path: '/:guid',
//name: 'HomeItemView',
//component: HomeItemView
//},
//{
//path: '/test/mode',
//name: 'TestMode',
//component: TestMode
//},
//{
//path: '/test/com',
//name: 'TestModeCom',
//component: TestModeCom
//},
//{
//path: '/dash/:dash_key/home',
//name: 'dash12',
//component: Homepage
//},
//{
//path: '/dash/:dash_key/items',
//name: 'item',
//component: ItemView
//},
//{
//path: '/dash/:dash_key/items/:guid',
//name: 'itembyguid',
//component: ItemEdit
//},


//{
// path: '/login',
// name: 'login',
// component: login
//},
{
path: '/about',
name: 'about',
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
}
]

const router = new VueRouter({
mode: 'history',
base: process.env.BASE_URL,
routes
})

export default router
