<template>
<div class="home-footer" v-on:click="">
<div class="home-footer-warp animate__animated animate__bounceInUp">
<div id="animate-footer-info" class="home-footer-item-box animate__animated" data-animation="animate__fadeInLeft"
 v-observe-visibility="visibilityChanged">
<FooterInfo></FooterInfo>
</div>
<div id="animate-footer-time" class="home-footer-item-box animate__animated" data-animation="animate__fadeInLeft"
 v-observe-visibility="visibilityChanged">
<FooterLinks></FooterLinks>
</div>
<div id="animate-footer-contact" class="home-footer-item-box animate__animated" data-animation="animate__fadeInRight"
 v-observe-visibility="visibilityChanged">
<FooterContact></FooterContact>
</div>

<!--<div id="animate-footer-maps" class="home-footer-item-box animate__animated" data-animation="animate__fadeInRight"
 v-observe-visibility="visibilityChanged">
<FooteMaps></FooteMaps>
</div>-->

<div class="home-footer-copyright-box"  style="">
<a href="https://api.whatsapp.com/send/?phone=966566081758&text&type=phone_number&app_absent=0"
target="_blank" style="font-size:16px;color:#3b3b3b;user-select:text!important;text-decoration:none;">
Powered By One Kod 
</a>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import FooterLinks from '../components/FooterLinks.vue';
import FooterInfo from '../components/FooterInfo.vue';
import FooterContact from '../components/FooterContact.vue';
export default {
name: 'Footer',
components: {FooterInfo,FooterLinks,FooterContact},
//props: {msg: String},
data(){
return {
     
}
},
methods: {
visibilityChanged:function(isVisible, entry) {
if(isVisible){
//console.log(entry.target.id);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
}
}
},
watch:{

},
computed: {
...mapState({
//selctedCat : state => state.selctedCat,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-footer{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#f9f9f9;
padding-top:7px;position:relative;z-index:0;
/*padding:14px;*/
font-family: 'Cairo', sans-serif;font-weight:400;
overflow:hidden;cursor:pointer;justify-content: center;
}
.home-footer-warp{
position:relative;z-index:0;display: flex;flex-wrap:wrap;
width: 100%;
max-width:1024px; justify-content: center;
align-content:center;margin-right:auto;margin-left:auto;

}
.home-footer-item-box{
position:relative;display:flex;
position:relative;z-index:0;
flex-wrap:wrap;border-bottom:1px dashed #ccc;
width:100%;
align-content:center;justify-content:center;
}
.home-footer-copyright-box{
position:relative;display:flex;padding-bottom:14px;padding-top:24px;
flex-wrap:wrap;
width:100%;height:auto;
align-content:center;justify-content:center;
}
.home-footer-copyright-box a{font-size:14px;color:#777;}

@media (min-width: 600px) and (max-width: 800px){
.home-footer-item-box{
width:49.33%;border:none;
min-height:300px;max-height:300px;overflow:hidden;
border-bottom:1px dashed #ccc;margin-bottom:7px;
}
}
@media (min-width: 800px) and (max-width: 1200px){
.home-footer-item-box{
width:49.75%;border:none;
min-height:300px;max-height:300px;overflow:hidden;
margin-bottom:7px;
}
}
@media (min-width: 1200px){
.home-footer-item-box{
width:33.33%;border:none;
min-height:300px;max-height:300px;overflow:hidden;
margin-bottom:7px;
}
.home-footer-item-box:first-child{}
.home-footer-item-box:nth-last-child(-n+2){}

}

</style>