<template>
<div class="contact-home-box">
<div style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;display: flex; flex-wrap:wrap;
height:auto;color:#353c45;width: 100%;justify-content:center; align-content: center;margin:auto;
padding:18px;max-width:1024px;">
<div class="home-contact-info">
<div style="height:auto;margin-top:18px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<span style="font-size:34px;color:#353c45;">إبداء معنا ألان !</span>
</div>
<div style="height: auto;margin-top:18px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<span style="font-size:22px;color:#353c45;margin-top:14px;">واحصل على اشتراك تجريبي <br v-if="width<575" />مجانا لمدة 14 يوم</span>
</div>
</div>
<!--<div style="margin-top:14px;height: 45px; font-size: 24px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<img src="img/sultann-x-logo-small.png" style="display:flex;max-height:45px;object-fit:contain;overflow:hidden;" />
</div>-->
<div class="home-contact-form" style="margin-top:14px;">
<div v-if="send_done==false" class="home-contact-form-box" style="margin-top:18px;color: #4e4e4e;">
<input v-model="input_name" type="text" lang="en" maxlength="50"
 placeholder="الاسم" style="min-width:300px;font-size: 16px;text-align:right;
padding-right:32px;"
 :class="code_ck==true ? 'in_code val-success':'in_code val-error'">
</div>
<div v-if="send_done==false" class="home-contact-form-box" style="margin-top:24px;color: #4e4e4e;">
<input v-model="input_phone" type="text" lang="en" inputmode="numeric" maxlength="10"
 placeholder="رقم الجوال" style="min-width:300px;font-size: 16px;text-align:right;
padding-right:32px;"
 :class="code_ck==true ? 'in_code val-success':'in_code val-error'">
</div>
<div v-if="send_done==false" class="home-contact-form-box">
<div class="home-contact-form-dark-btn" v-on:click="view_post" style="margin-top:32px;">
<span style="display: flex;flex-wrap:wrap;align-content: center; width:auto;height:55px;">
<span style="font-size: 18px;">ارسال</span>
</span>
</div>
</div>
<div v-if="send_done==true" style="margin-top:24px;height: auto;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important;
 width: 100%;touch-action: pan-y !important;">
<span style="width:36px;height:36px;border-radius:50%;background:rgba(82, 180, 74,0.1);display: flex; flex-wrap:wrap; align-content: center;justify-content: center; ">
<img src="img/done.svg" style="display:flex;justify-content:center;width:100%;height:100%;object-fit:contain;" />
</span>
</div>
<div v-if="send_done==true" style="font-size: 16px;padding: 14px 0px 14px 0px;
display: flex; flex-wrap:wrap; align-content: center;
justify-content: center !important; width: 100%; touch-action: pan-y !important; ">
<span style="text-align:center;color:#353c45;font-size: 18px;"> تم ارسال طلبك بنجاح وسيتم التواصل معك من قبل فريقنا. </span>
</div>
</div>

</div>
</div>
</template>
<script>
import axios from 'axios';
export default {
name: 'Contact',
components: {},
//props: ['text'],
data(){
return {
ck_posts:[],
code_ck:false,
input_name:'',
input_phone:'',
send_done:false,
send_data:[],
popup:'',
width:window.innerWidth  
}
},
methods: {
view_post:function () {
var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})|[\u0660-\u0669]{10}$/);
if (this.input_phone != ''){
if(regex.test(this.input_phone)){
var date = new Date();
var second = date.toLocaleString("en-US", {timeZone: "Asia/Riyadh", second: "2-digit"});
var minute= date.toLocaleString("en-US", {timeZone: "Asia/Riyadh", minute: "2-digit"});
this.send_data.push({name:this.input_name,mobile:this.input_phone});
axios.post('https://onekod.com/api/call_us.aspx?guid='+second+'bc5q'+minute+'7zm',this.send_data,{
headers: {
'Content-Type': 'application/json'
}
})
.then((response) => {
if(response.data.status=='ok'){
this.send_done=true;
this.Swal_Success('تم ارسال طلبك بنجاح');
}
else{
this.Swal_Error(response.data.status);
}
},(error) => {
this.Swal_Error(error);
});
}
else{
this.Swal_Error('رقم الجوال غير صحيح !!');
}
}
else{
this.Swal_Error('الرجاء اضافة الاسم ورقم الجوال !!');
}
},
//Swal_Success
Swal_Success:function (val){
this.$store.commit("Swal_Success", val);
},
Swal_Error:function (val){
this.$store.commit("Swal_Error", val);
},
isNumberKey: function (event){
//  var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
/*  if (regex.test(this.input_mobile)) {this.mobile_ck = true;}*/
var charCode = (event.which) ? event.which : event.keyCode
if (charCode > 31 && (charCode < 48 || charCode > 57))
{
this.input_phone = this.input_phone.toString().replace(/[^0-9]/g, '');
//this.input_mobile = this.input_mobile.slice(0, -1);
return this.Swal_Error('الحروف غير مسموح بها !!');
}
return true;
},
btn_show:function (val){
$(val).hide();
$(val).show();
$(val).removeClass("animate__animated animate__fadeOutLeft animate__faster");
$(val).addClass("animate__animated animate__fadeInLeft animate__faster");
},
},
mounted(){
window.addEventListener('resize',()=>{
this.width=window.innerWidth
});
//document.title='عرض | QrCode';
},
watch:{

}
}
</script>
<style scoped>
.contact-home-box{
background:none;z-index:0;position:relative;cursor:pointer;
display: flex; flex-wrap: wrap;height:auto;width:100%;
justify-content:center;align-content:center;padding-right:18px;padding-left:18px;
color:#4e4e4e;font-weight:500;white-space: pre-wrap;font-size:16px;text-align:center;
}
.home-contact-info{
position:relative; display: flex; flex-wrap:wrap;height:auto;
width: 100%;justify-content:center; align-content: center;
}
.home-contact-form{
position:relative;display: flex;flex-wrap:wrap;height:auto;
width: 100%;justify-content:center; align-content: center;
}
.home-contact-form-box{
position:relative;display:flex;flex-wrap:wrap;width: 100%;
align-content: center;justify-content: center !important; 
}
input[type="text"] {
background-color: rgb(252, 252, 251);
min-height:64px;
border:1px solid #aaaaaa;
border-radius: 32px;
font-size:16px;
outline: none !important;
overflow: hidden !important;
text-align:center;
}
.home-contact-form-dark-btn{
display: flex;flex-wrap:wrap;
align-content: center;justify-content: center !important;
background:#353c45;color:#fff;
border-radius:32px;width:300px;height:72px;max-height:72px;
}

@media (min-width: 1024px){
.home-contact-info{
width:94%;align-content:flex-start;margin-bottom:7px;
}
.home-contact-form{
width: 94%;align-content:flex-start;margin-bottom:14px;
}
.home-contact-form-box{
width: auto;margin-left:14px;margin-right:auto;
}
.home-contact-form-box:last-child{
width: auto;margin-right:auto;
}
.home-contact-form-dark-btn{
width:224px;height:65px;border-radius:48px;
}
}
</style>
